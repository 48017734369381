import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn } from '@angular/router';

import { AuthService } from '@/modules/auth/services/auth.service';
import { UserRole } from '@/modules/auth/constants/user-role.constant';

export const authGuard: CanActivateFn = (): boolean => {
  const authService: AuthService = inject(AuthService);

  if (!authService.isLoggedIn()) {
    authService.redirectToLogin();
  }

  return authService.isLoggedIn();
};

export const loginGuard: CanActivateFn = (): boolean => {
  const authService: AuthService = inject(AuthService);

  if (authService.isLoggedIn()) {
    authService.redirectToHome();
  }

  return !authService.isLoggedIn();
};

export function hasRoleGuard(roles: Array<UserRole>): CanMatchFn {
  return (): boolean => {
    const authService: AuthService = inject(AuthService);

    return authService.userHasRoles(roles);
  };
}
